// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    });
  }

import '../styles/application.scss';
import '../styles/tailwind.css';

import 'details-element-polyfill'
import { Application } from 'stimulus'
import 'focus-visible'
import './lazy-load'

window.Stimulus = Application.start()

import ActivityFilters from './controllers/activity-filters_controller.js';
import Autocomplete from './controllers/autocomplete_controller.js';
import BackToTop from './controllers/back-to-top_controller.js';
import Banner from './controllers/banner_controller.js';
import Card from './controllers/card_controller.js';
import CardSlider from './controllers/card-slider_controller.js';
import CurrentUrl from './controllers/current-url_controller.js';
import DestinationFilters from './controllers/destination-filters_controller.js';
import Dropdown from './controllers/dropdown_controller.js';
import DurationSelect from './controllers/duration-select_controller.js';
import Faq from './controllers/faq_controller.js';
import Form from './controllers/form_controller.js';
import InfiniteScroller from './controllers/infinite-scroller_controller.js';
import Link from './controllers/link_controller.js';
import Map from './controllers/map_controller.js';
import Modal from './controllers/modal_controller.js';
import MultiSelect from './controllers/multi-select_controller.js';
import Nav from './controllers/nav_controller.js';
import Pagination from './controllers/pagination_controller.js';
import TravelBlogFilters from './controllers/travel-blog-filters_controller.js';
import RangeSlider from './controllers/range-slider_controller.js';
import ReadMore from './controllers/read-more_controller.js';
import ScrollIndicator from './controllers/scroll-indicator_controller.js';
import SearchResults from './controllers/search-results_controller.js';
import SimpleModal from './controllers/simple-modal_controller.js';
import SiteSwitcherModal from './controllers/site-switcher-modal_controller.js';
import SliderNav from './controllers/slider-nav_controller.js';
import Slider from './controllers/slider_controller.js';
import Social from './controllers/social_controller.js';
import SortSelect from './controllers/sort-select_controller.js';
import Subnav from './controllers/subnav_controller.js';
import Summary from './controllers/summary_controller.js';
import Tabs from './controllers/tabs_controller.js';
import TextExpander from './controllers/text-expander_controller.js';
import Toggler from './controllers/toggler_controller.js';
import TooltipInject from './controllers/tooltip-inject_controller.js';
import TopBar from './controllers/top-bar_controller.js';
import TripSlider from './controllers/trip-slider_controller.js';
import TripStickyNav from './controllers/trip-sticky-nav_controller.js';
import Wishlist from './controllers/wishlist_controller.js';

Stimulus.register('activity-filters', ActivityFilters);
Stimulus.register('autocomplete', Autocomplete);
Stimulus.register('back-to-top', BackToTop);
Stimulus.register('banner', Banner);
Stimulus.register('card', Card);
Stimulus.register('card-slider', CardSlider);
Stimulus.register('current-url', CurrentUrl);
Stimulus.register('destination-filters', DestinationFilters);
Stimulus.register('dropdown', Dropdown);
Stimulus.register('duration-select', DurationSelect);
Stimulus.register('faq', Faq);
Stimulus.register('form', Form);
Stimulus.register('infinite-scroller', InfiniteScroller);
Stimulus.register('link', Link);
Stimulus.register('map', Map);
Stimulus.register('modal', Modal);
Stimulus.register('multi-select', MultiSelect);
Stimulus.register('nav', Nav);
Stimulus.register('pagination', Pagination);
Stimulus.register('travel-blog-filters', TravelBlogFilters);
Stimulus.register('range-slider', RangeSlider);
Stimulus.register('read-more', ReadMore);
Stimulus.register('scroll-indicator', ScrollIndicator);
Stimulus.register('search-results', SearchResults);
Stimulus.register('simple-modal', SimpleModal);
Stimulus.register('site-switcher-modal', SiteSwitcherModal);
Stimulus.register('slider-nav', SliderNav);
Stimulus.register('slider', Slider);
Stimulus.register('social', Social);
Stimulus.register('sort-select', SortSelect);
Stimulus.register('subnav', Subnav);
Stimulus.register('summary', Summary);
Stimulus.register('tabs', Tabs);
Stimulus.register('text-expander', TextExpander);
Stimulus.register('toggler', Toggler);
Stimulus.register('tooltip-inject', TooltipInject);
Stimulus.register('top-bar', TopBar);
Stimulus.register('trip-slider', TripSlider);
Stimulus.register('trip-sticky-nav', TripStickyNav);
Stimulus.register('wishlist', Wishlist);
