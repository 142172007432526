import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.onClick = this.onClick.bind(this)
    this.close = this.close.bind(this)
    this.modalArray = []
    this.initializeLinks()

    window.addEventListener('resize', this.close)
    window.addEventListener('scroll', this.close)
    window.addEventListener('infinite-scroll-load', this.initializeLinks.bind(this))
  }

  initializeLinks() {
    this.links = Array.prototype.slice.call(this.element.querySelectorAll('a[href*="/tooltips/"], [data-href*="/tooltips/"]'))
    this.links.forEach(link => {
      link.classList.add('tooltip')
      this.modalArray.push(false)
      link.removeEventListener('click', this.onClick)
      link.addEventListener('click', this.onClick)
    })
  }

  close() {
    this.modalArray.forEach(modal => {
      if (modal !== false) {
        modal.hide(new Event('click'))
        modal.contentTarget.setAttribute('style', '')
      }
    })
  }

  show(index) {
    this.position(index)
    this.modalArray[index].show(new Event('click'))
  }

  async onClick(event) {
    event.preventDefault()
    event.stopPropagation()
    this.currentLink = event.currentTarget
    this.currentIndex = this.links.indexOf(this.currentLink)

    if (this.currentLink.classList.contains('is-loading')) {
      return false
    }

    if (this.modalArray[this.currentIndex] !== false) {
      this.show(this.currentIndex)
    } else {
      this.currentLink.classList.add('is-loading')
      this.currentLink.search += '&render'

      let url = this.currentLink.getAttribute('data-href') ? this.currentLink.getAttribute('data-href') + '?render' : this.currentLink.href
      
      const response = await fetch(url)
      const text = await response.text()

      this.wrapper = document.createElement('div')
      this.wrapper.innerHTML = text

      document.body.appendChild(this.wrapper)

      this.interval = setInterval(() => this.initModal(this.currentIndex), 100)
    }

    return false
  }

  initModal(index) {
    const modal = this.wrapper.querySelector('div')
    const controller = this.application.getControllerForElementAndIdentifier(modal, 'modal')

    if (controller) {
      clearInterval(this.interval)

      this.modalArray[index] = controller
      this.position(index)
      controller.show(new Event('click'))
      this.links[index].classList.remove('is-loading')
    }
  }

  position(index) {
    const controller = this.modalArray[index]
    const link = this.links[index]
    const { top, x } = link.getBoundingClientRect()
    const elementOffsetY = window.scrollY + top + link.offsetHeight
    const elementOffsetX = x + link.offsetWidth + 10

    controller.contentTarget.style.top = `${elementOffsetY}px`
    controller.contentTarget.style.left = `${elementOffsetX}px`

    const offscreenX = this.offscreenXBy(controller.contentTarget)
    if (offscreenX > 0) {
      controller.contentTarget.style.left = `${elementOffsetX - offscreenX - 20}px`
    }

    const offscreenY = this.offscreenYBy(controller.contentTarget)
    if (offscreenY > 0) {
      controller.contentTarget.style.top = `${elementOffsetY - offscreenY - 20}px`
    }
  }

  offscreenXBy(element) {
    const rect = element.getBoundingClientRect()
    let value = 0

    if (rect.x + rect.width > window.innerWidth) {
      value = rect.x + rect.width - window.innerWidth
    }

    return value
  }

  offscreenYBy(element) {
    const rect = element.getBoundingClientRect()
    let value = 0

    if (rect.y + rect.height > window.innerHeight) {
      value = rect.y + rect.height - window.innerHeight
    }

    return value
  }
}
