import { Controller } from 'stimulus'

export default class TopBarController extends Controller {
  static targets = ['item']

  connect() {
    this.delay = 5000
    this.interval = null
    this.index = 0
    this.startAnimation()
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  startAnimation() {
    this.interval = setInterval(() => {
      if (this.isDesktop()) {
        return
      }
      const nextIndex = this.nextIndex()
      this.itemTargets[this.index].classList.add('header__top-bar__item--hidden')
      this.itemTargets[nextIndex].classList.remove('header__top-bar__item--hidden')
      this.index = nextIndex
    }, this.delay)
  }

  isDesktop() {
    return window.matchMedia('(min-width: 1024px)').matches
  }

  nextIndex() {
    return this.index >= this.itemTargets.length - 1 ? 0 : this.index + 1
  }
}
