import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [
      'nav',
      'background',
      'menu',
      'drawer',
      'subMenu',
      'subMenuContent',
      'search',
      'searchIcon',
      'flyOver',
    ]
  }

  initialize() {
    this.isMobile = window.matchMedia('(max-width: 1023px)').matches
    this.previousScrollY = 0
    this.open = false
    this.minHeightThreshold = this.element.dataset.minHeightThreshold || false
    this.disableSticky = this.checkIfStickyDisabled()
    this.openCloseTimeout = null
    this.changeTimeout = null
    this.currentTemplateId = null
    this.activeSectionButton = null
    this.enterDelay = 200
    this.exitDelay = 200
    this.changeDelay = 350
    this.navItems = this.cacheNavItems()
  }

  connect() {
    window.addEventListener('scroll', this.onScroll.bind(this))
    window.addEventListener('resize', this.onResize.bind(this))
  }

  disconnect() {
    window.removeEventListener('scroll', this.onScroll.bind(this))
    window.removeEventListener('resize', this.onResize.bind(this))
  }

  cacheNavItems() {
    const templates = document.querySelectorAll('.navigation-template')
    const navItems = {};
    for (let i = 0; i < templates.length; i++) {
      navItems[templates[i].id] = templates[i].firstElementChild.cloneNode(true)
    }
    return navItems;
  }

  onResize() {
    this.isMobile = window.matchMedia('(max-width: 1023px)').matches
    this.disableSticky = this.checkIfStickyDisabled()
    if (this.disableSticky) {
      this.element.classList.remove('header--sticky')
    }
    this.onScroll()
  }

  onScroll() {
    if (this.disableSticky || this.open) {
      return
    }

    const currentScrollY = window.scrollY

    if (currentScrollY > this.previousScrollY) {
      // If the user is scrolling down, make the navbar static.
      this.element.classList.remove('header--sticky')
    } else if (this.previousScrollY - currentScrollY > 7) {
      // If the user is scrolling up fast enough, make the navbar sticky.
      this.element.classList.add('header--sticky')
    }

    this.previousScrollY = currentScrollY
  }

  toggleMenu() {
    this.open = !this.open
    this.navTarget.classList.toggle('is-open')
    this.searchTarget.classList.remove('header-search--visible')
    document.documentElement.classList.toggle('overflow-hidden')
    setTimeout(() => this.closeSubMenu(), 500)
  }

  closeMenu() {
    this.open = false
    this.navTarget.classList.remove('is-open')
    document.documentElement.classList.remove('overflow-hidden')
    setTimeout(() => this.closeSubMenu(), 500)
  }

  toggleSearch() {
    this.closeMenu()
    this.searchTarget.classList.toggle('header-search--visible')
    this.searchIconTarget.classList.toggle('nav__item--search__icon--open')
    const input = document.getElementById('nav-search-input')
    if (input === document.activeElement) {
      input.blur()
    } else {
      input.focus()
    }
  }

  changeSubMenu(e) {
    if (e && e.target.dataset.id) {
      this.currentTemplateId = e.target.dataset.id
    }
    const template = this.navItems[`navigation-template-${this.currentTemplateId}`];
    if (!template) {
      return
    }
    this.subMenuTarget.innerHTML = ''
    this.subMenuTarget.scrollTop = 0
    this.subMenuTarget.appendChild(template)
    this.changeTimeout = null
  }

  openSubMenuOnMobile(e) {
    if (!this.isMobile) {
      return
    }
    this.drawerTarget.style.transform = 'translateX(-50%)'
    this.changeSubMenu(e)
    document.documentElement.classList.add('overflow-hidden')
  }

  openSubMenuOnDesktop() {
    if (this.isMobile || this.open) {
      return
    }
    if (this.openCloseTimeout) {
      clearTimeout(this.openCloseTimeout)
      this.openCloseTimeout = null
    }
    this.openCloseTimeout = setTimeout(() => {
      this.open = true
      this.navTarget.classList.add('is-open')
      this.drawerTarget.style.transform = null
      this.flyOverTarget.classList.remove('nav__flyover--hidden')

      if (!this.disableSticky) {
        this.element.classList.add('header--sticky')
      }
    }, this.enterDelay)
  }

  closeSubMenu() {
    this.drawerTarget.style.transform = 'translateX(0)'
  }

  closeSubMenuOnDesktop(e) {
    if (this.isMobile) {
      return
    }
    if (this.openCloseTimeout) {
      clearTimeout(this.openCloseTimeout)
      this.openCloseTimeout = null
    }
    this.open = false
    this.openCloseTimeout = setTimeout(() => {
      if (this.activeSectionButton !== null && this.activeSectionButton !== e.target) {
        this.activeSectionButton.classList.remove('nav__section-button--active')
        this.activeSectionButton = null
      }
      this.navTarget.classList.remove('is-open')
      this.flyOverTarget.classList.add('nav__flyover--hidden')
      document.documentElement.classList.remove('overflow-hidden')
      this.openCloseTimeout = null
    }, this.exitDelay)
  }

  changeSubMenuOnDesktop(e) {
    if (this.isMobile) {
      return
    }

    const delay = this.open ? this.changeDelay : 0

    if (e.target) {
      e.target.classList.add('nav__section-button--active')
    }

    this.cancelChangeSubMenuOnDesktop()
    this.changeTimeout = setTimeout(() => {
      if (this.activeSectionButton !== null && this.activeSectionButton !== e.target) {
        this.activeSectionButton.classList.remove('nav__section-button--active')
      }
      this.activeSectionButton = e.target
      this.changeSubMenu(e)
    }, delay)
  }

  cancelChangeSubMenuOnDesktop(e) {
    if (this.isMobile) {
      return
    }
    if (e && e.target !== this.activeSectionButton) {
      e.target.classList.remove('nav__section-button--active')
    }
    if (this.changeTimeout) {
      clearTimeout(this.changeTimeout)
      this.changeTimeout = null
    }
  }

  subMenuBack(e) {
    e.preventDefault()
    e.stopPropagation()
    this.menuTarget.scrollTop = 0
    this.drawerTarget.style.transform = 'translateX(0%)'
    setTimeout(() => {
      this.subMenuTarget.innerHTML = ''
    }, 500)
  }

  subMenuShouldChange(e) {
    return e.target.dataset.id !== undefined && this.currentTemplateId !== e.target.dataset.id
  }

  checkIfStickyDisabled() {
    return (
      this.element.dataset.disableSticky === 'true' ||
      (this.minHeightThreshold &&
        window.matchMedia(`(max-height: ${this.minHeightThreshold}px)`).matches)
    )
  }
}
