import { Controller } from 'stimulus'
import { lazyLoad } from '../lazy-load'

export default class extends Controller {
  static get targets() {
    return ['loadingIndicator', 'loadingMessage', 'contentSection', 'total']
  }

  static values = {
    allContentLoaded: Boolean,
    slug: String
  }

  initialize() {
    this.onScroll(this)
    this.offset = 800
    this.loading = false
    this.page = 2
    this.perPage = parseInt(this.element.dataset.perPage)
    this.destinationId = parseInt(this.element.dataset.destinationId)
    this.total = this.parseTotal()
    this.itemCount = document.getElementsByClassName('inifinite-scroller-item').length
    this.slug = this.element.dataset.slug
    this.lastRequestTimestamp = Date.now()

    this.scrollHandler = this.onScroll.bind(this)
    this.filterChangeHandler = this.reloadPages.bind(this)
  }

  connect() {
    window.addEventListener('scroll', this.scrollHandler)
    window.addEventListener('filter-change', this.filterChangeHandler)
    this.dispatchLoadEvent()
  }

  disconnect() {
    window.removeEventListener('scroll', this.scrollHandler)
    window.removeEventListener('filter-change', this.filterChangeHandler)
  }

  onScroll() {
    if (this.loading || this.allLoaded()) { return }
    const clientHeight = this.contentSectionTarget.getBoundingClientRect().bottom
    const triggerLoad = clientHeight - (window.innerHeight || document.documentElement.clientHeight) < this.offset
    if (triggerLoad) {
      this.loadNextPage()
      this.onScroll()
    }
  }

  async loadNextPage() {
    if (this.loading || this.allLoaded()) { return }
    try {
      this.lastRequestTimestamp = Date.now()
      const currentRequestTimestamp = this.lastRequestTimestamp
      this.startLoading()
      const url = new URL(window.location.href)
      url.pathname = `${url.pathname}/page`
      url.searchParams.set('page', this.page)
      url.searchParams.set('perPage', this.perPage)
      url.searchParams.set('destinationId', this.destinationId)
      url.searchParams.set('include', '1')
      const response = await fetch(url)
      if (currentRequestTimestamp < this.lastRequestTimestamp) {
        this.stopLoading()
        return
      }
      this.contentSectionTarget.insertAdjacentHTML('beforeend', await response.text())
      this.contentSectionTarget.classList.remove('hidden')
      this.page++
      this.itemCount = document.getElementsByClassName('inifinite-scroller-item').length
      this.total = this.parseTotal()
      this.dispatchLoadEvent()
      this.stopLoading()

      lazyLoad()
    } catch (err) {
      this.loadingMessageTarget.textContent = 'Failed to load more holidays. Please try again later.'
      console.error(`Failed to load more holidays: ${err}`)
      this.stopLoading()
    }
  }

  async reloadPages() {
    try {
      this.page = 1
      this.lastRequestTimestamp = Date.now()
      const currentRequestTimestamp = this.lastRequestTimestamp
      this.startLoading()
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.contentSectionTarget.classList.add('hidden')
      const url = new URL(window.location.href)
      url.pathname = `${url.pathname}/page`
      url.searchParams.set('page', this.page)
      url.searchParams.set('perPage', this.perPage)
      url.searchParams.set('destinationId', this.destinationId)
      url.searchParams.set('include', '1')
      url.searchParams.set('filters-enabled', '1')
      const response = await fetch(url)
      if (currentRequestTimestamp < this.lastRequestTimestamp) {
        return
      }
      this.contentSectionTarget.innerHTML = await response.text()
      this.contentSectionTarget.classList.remove('hidden')
      this.page++
      this.itemCount = document.getElementsByClassName('inifinite-scroller-item').length
      this.total = this.parseTotal()
      this.dispatchLoadEvent()
      this.stopLoading()
    } catch (err) {
      this.loadingMessageTarget.textContent = 'Failed to load more holidays. Please try again later.'
      console.error(`Failed to load more holidays: ${err}`)
      this.stopLoading()
    }
  }

  allLoaded() {
    return this.itemCount >= this.total
  }

  parseTotal() {
    return parseInt(this.totalTarget.textContent.trim())
  }

  dispatchLoadEvent() {
    window.dispatchEvent(new CustomEvent('infinite-scroll-load', { detail: { total: this.total }}))
  }

  startLoading() {
    this.loading = true
    this.loadingIndicatorTarget.classList.remove('hidden')
  }

  stopLoading() {
    this.loading = false
    this.loadingIndicatorTarget.classList.add('hidden')
  }
}
