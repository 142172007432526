import Flickity from 'flickity-as-nav-for'
import 'flickity-imagesloaded'
import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['slider', 'slides', 'slide']
  }

  static get options() {
    return {
      draggable: true,
      dragThreshold: 10,
      groupCells: false,
      pageDots: true,
      contain: true,
      prevNextButtons: true,
      wrapAround: true,
      imagesLoaded: true,
      cellAlign: 'center',
      setGallerySize: false,
      lazyLoad: 2
    }
  }

  initialize() {
    this.url = this.element.dataset.url
    this.mobileMediaQuery = window.matchMedia('(max-width: 767px)')

    if (this.mobileMediaQuery.matches) {
      this.options.draggable = false
      this.options.pageDots = false
      this.options.prevNextButtons = false
    }

    this.flickity = new Flickity(this.slidesTarget, this.options)

    // Fix Flickity viewport height calculation (see https://github.com/metafizzy/flickity/issues/205#issuecomment-246212994)
    window.addEventListener('load', () => this.flickity.resize())

    window.addEventListener('resize', () => {
      this.flickity.resize()
    })

    // Stop any clicks on flickity nav from triggering a click on a parent HTML link
    document.addEventListener('click', (e) => {
      if (
        e.target.matches('a .flickity-button-icon') 
        || e.target.matches('a .flickity-button-icon *')
        || e.target.matches('a .flickity-page-dots')
        || e.target.matches('a .flickity-page-dots *')
        )
      {
        e.preventDefault()
      }
    })
  }
}
